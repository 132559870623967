import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import ContactField from '../CmsContactUs/ContactField';

import { emailValidationRegex } from '../Forms/utils';

const RecommendFriendForm = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    yourName: '',
    yourEmail: '',
    yourPhoneNumber: '',
    theirName: '',
    theirEmail: '',
    theirPhoneNumber: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form
      className="c-form-simple"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      <div className="g-contact-form__content">
        <div className="b-form-group-modern">
          <h2 className="c-intro__sub-heading pb-4">Your details</h2>
          <ContactField
            inputClassName="b-form-control-modern"
            labelText="Your name"
            id="your_name"
            type="text"
            onChange={(event) => setState({ ...state, yourName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.your_name}
            value={state.yourName}
            hiddenLabel={false}
          />

          <ContactField
            inputClassName="b-form-control-modern"
            type="email"
            labelText="Your email address"
            id="your_email"
            onChange={(event) => setState({ ...state, yourEmail: event.target.value })}
            ref={register({
              required: true,
              pattern: emailValidationRegex,
            })}
            hasError={!!errors.your_email}
            value={state.yourEmail}
            hiddenLabel={false}
          />

          <ContactField
            inputClassName="b-form-control-modern"
            type="tel"
            labelText="Your phone number"
            id="your_phone_number"
            onChange={(event) => setState({ ...state, yourPhoneNumber: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.your_phone_number}
            value={state.yourPhoneNumber}
            hiddenLabel={false}
          />
        </div>

        <div className="b-form-group-modern">
          <h2 className="c-intro__sub-heading pb-4">Friend&apos;s Details</h2>

          <ContactField
            inputClassName="b-form-control-modern"
            labelText="Friend's name"
            id="their_name"
            type="text"
            onChange={(event) => setState({ ...state, theirName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.their_name}
            value={state.theirName}
            hiddenLabel={false}
          />

          <ContactField
            inputClassName="b-form-control-modern"
            type="email"
            labelText="Friend's email address"
            id="their_email"
            onChange={(event) => setState({ ...state, theirEmail: event.target.value })}
            ref={register({
              validate: {
                required: (value) => {
                  if (value === '' && state.theirPhoneNumber === '') {
                    return "Enter friend's email or phone number.";
                  }
                  return true;
                },
                pattern: (value) => {
                  return value === '' || emailValidationRegex.test(value) || 'Invalid email address';
                },
              },
            })}
            errorMessage={errors.their_email?.message}
            hasError={!!errors.their_email}
            value={state.theirEmail}
            hiddenLabel={false}
          />

          <ContactField
            inputClassName="b-form-control-modern"
            type="tel"
            labelText="Friend's phone number"
            id="their_phone_number"
            onChange={(event) => setState({ ...state, theirPhoneNumber: event.target.value })}
            ref={register({
              validate: {
                required: (value) => {
                  if (value === '' && state.theirEmail === '') {
                    return "Enter friend's phone number or email.";
                  }
                  return true;
                },
                isValidNumber: (value) => {
                  return value === '' || isValidNumber(value, 'GB') || 'Invalid phone number';
                },
              },
            })}
            errorMessage={errors.their_phone_number?.message}
            hasError={!!errors.their_phone_number}
            value={state.theirPhoneNumber}
            hiddenLabel={false}
          />
        </div>
      </div>

      <div className="g-form__footer">
        <p className="g-form__asterix">
          *Amazon.co.uk is not a sponsor of this promotion. Amazon.co.uk Gift Cards (&quot;GCs&quot;) may be redeemed on
          the Amazon.co.uk website towards the purchase of eligible products available on www.amazon.co.uk. GCs cannot
          be reloaded, resold, transferred for value, redeemed for cash or applied to any other account. Amazon.co.uk is
          not responsible if a GC is lost, stolen, destroyed or used without permission. See www.amazon.co.uk/gc-legal
          for complete terms and conditions. GCs are issued by Amazon EU S.a.r.l. All Amazon (reg), TM &amp; C are IP of
          Amazon.com. Inc or its affiliates.
        </p>
      </div>

      <div className="b-form-group">
        <button className="g-contact-form__submit g-contact-form__submit--gold" type="submit" disabled={state.disabled}>
          {state.disabled ? 'Sending...' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

RecommendFriendForm.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default RecommendFriendForm;
