import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';
import TimeSelector from '../../../../Forms/TimeSelector';

const BankDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCABankInfo);

  return (
    <>
      <InputField
        labelText="Bank name"
        id="business-bank-name"
        placeholder="Bank name"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_BANK_NAME', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['business-bank-name']}
        value={query.businessBankName}
      />

      <TimeSelector
        labelText="Time with bank"
        id="time-with-business-bank"
        yearsDispatch="SET_YEARS_WITH_BUSINESS_BANK"
        monthsDispatch="SET_MONTHS_WITH_BUSINESS_BANK"
        required
        ref={props.register({ required: true })}
        hasError={props.validationErrors['time-with-business-bank']}
        value={[query.yearsWithBusinessBank, query.monthsWithBusinessBank]}
      />
    </>
  );
};

BankDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'business-bank-name': PropTypes.shape({}),
    'time-with-business-bank': PropTypes.shape({}),
  }).isRequired,
};

export default BankDetails;
