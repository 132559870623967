import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import BusinessDetails from './partials/BusinessDetails';
import PremisesHistory from './partials/PremisesHistory';

const CompanyInfo = ({ register, unregister, validationErrors }) => {
  return (
    <div className="row">
      <div className="g-order__fields g-order__fields--primary">
        <BusinessDetails register={register} validationErrors={validationErrors} />
      </div>

      <div className="g-order__fields g-order__fields--secondary">
        <PremisesHistory register={register} unregister={unregister} validationErrors={validationErrors} />
      </div>
    </div>
  );
};

CompanyInfo.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  validationErrors: PropTypes.shape({
    'business-name': PropTypes.shape({}),
    'business-type': PropTypes.shape({}),
    'company-number': PropTypes.shape({}),
    'vat-number': PropTypes.shape({}),
    'number-of-directors': PropTypes.shape({}),
    'business-email': PropTypes.shape({}),
    'company-postcode': PropTypes.shape({}),
    'company-address-line-1': PropTypes.shape({}),
    'company-town': PropTypes.shape({}),
    'company-county': PropTypes.shape({}),
    'company-property-status': PropTypes.shape({}),
    'time-at-company-address': PropTypes.shape({}),
    'company-telephone': PropTypes.shape({}),
  }),
};

CompanyInfo.defaultProps = {
  register: null,
  unregister: null,
  validationErrors: null,
};

export default CompanyInfo;
