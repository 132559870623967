import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import classNames from 'classnames';

const SaturdayBanner = ({ telephone_number }) => {
  const bodyClasses = document.body.classList;

  const [state, setState] = useState({
    showModal: false,
  });

  const modalRef = useRef(null);
  const modalBodyRef = useRef(null);

  const classes = classNames({
    'modal fade c-modal c-modal-saturday-banner modal-open': true,
    show: state.showModal,
  });

  const handleClose = (event) => {
    event.preventDefault();

    Cookies.set('saturday_banner_shown', 'true', { expires: 365 });

    // Hide modal
    setState({ ...state, showModal: false });
    modalRef.current.style.display = 'none';

    const saturdayBannerBackdrop = document.getElementById('saturday-banner-backdrop');
    saturdayBannerBackdrop && saturdayBannerBackdrop.remove();

    bodyClasses.remove('has-modal', 'modal-open');

    // Remove event listeners
    // eslint-disable-next-line no-use-before-define
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const handleClickOutside = (event) => {
    if (modalBodyRef && modalBodyRef.current && !modalBodyRef.current.contains(event.target)) {
      handleClose(event);
    }
  };

  const handleOpen = () => {
    if (Cookies.get('saturday_banner_shown') === 'false') {
      setTimeout(
        () => {
          setState({ ...state, showModal: true });
          bodyClasses.add('has-modal', 'modal-open');
          document.body.insertAdjacentHTML(
            'beforeend',
            '<div class="modal-backdrop fade show" id="saturday-banner-backdrop"></div>',
          );
        },
        0,
        (modalRef.current.style.display = 'block'),
      );
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setState({ ...state, showModal: false });
    }
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <div className={classes} role="dialog" aria-labelledby="saturday-banner-heading" ref={modalRef}>
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="c-modal-saturday-banner__actions">
            <button
              type="button"
              className="close c-modal-saturday-banner__close"
              aria-label="Close"
              onClick={handleClose}
            >
              Close <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="c-modal-saturday-banner__body" ref={modalBodyRef}>
            <h2 className="c-modal-saturday-banner__heading">We are open today for new enquiries</h2>
            <p className="c-modal-saturday-banner__info">For anything else please call Mon to Fri 09.00 - 18.00</p>
            <p className="c-modal-saturday-banner__telephone">
              Simply give us a call on <a href="tel:{telephone_number}">{telephone_number}</a>
            </p>
          </div>
          <div className="c-modal-saturday-banner__image">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

SaturdayBanner.propTypes = {
  telephone_number: PropTypes.string.isRequired,
};

export default SaturdayBanner;
