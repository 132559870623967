import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';
import TimeSelector from '../../../../Forms/TimeSelector';

const BankAccountDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAFinances);

  return (
    <>
      <InputField
        labelText="Bank name"
        id="bank-name"
        placeholder="Bank name"
        onChange={(event) => dispatch({ type: 'SET_BANK_NAME', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['bank-name']}
        value={query.bankName}
      />

      <InputField
        labelText="Bank address"
        id="bank-address"
        placeholder="Bank address"
        onChange={(event) => dispatch({ type: 'SET_BANK_ADDRESS', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['bank-address']}
        value={query.bankAddress}
      />

      <TimeSelector
        labelText="Time with bank"
        id="time-with-bank"
        yearsDispatch="SET_YEARS_WITH_BANK"
        monthsDispatch="SET_MONTHS_WITH_BANK"
        required
        ref={props.register({ required: true })}
        hasError={props.validationErrors['time-with-bank']}
        value={[query.yearsWithBank, query.monthsWithBank]}
      />

      <InputField
        labelText="Account name"
        id="bank-account-name"
        placeholder="Account name"
        onChange={(event) => dispatch({ type: 'SET_BANK_ACCOUNT_NAME', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['bank-account-name']}
        value={query.bankAccountName}
      />

      <InputField
        type="number"
        labelText="Account number"
        id="bank-account-number"
        placeholder="Account number"
        onChange={(event) => dispatch({ type: 'SET_BANK_ACCOUNT_NUMBER', payload: event.target.value })}
        ref={props.register({ required: true, minLength: 7, maxLength: 12 })}
        hasError={!!props.validationErrors['bank-account-number']}
        value={query.bankAccountNumber}
      />

      <InputField
        type="number"
        labelText="Sortcode"
        id="bank-sort-code"
        placeholder="Sortcode"
        onChange={(event) => dispatch({ type: 'SET_BANK_SORT_CODE', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['bank-sort-code']}
        value={query.bankSortCode}
      />
    </>
  );
};

BankAccountDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'bank-name': PropTypes.shape({}),
    'bank-address': PropTypes.shape({}),
    'time-with-bank': PropTypes.shape({}),
    'bank-account-name': PropTypes.shape({}),
    'bank-account-number': PropTypes.shape({}),
    'bank-sort-code': PropTypes.shape({}),
  }).isRequired,
};

export default BankAccountDetails;
