import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import BankDetails from './partials/BankDetails';
import BankAccountDetails from './partials/BankAccountDetails';

const BankInfo = ({ register, validationErrors }) => {
  return (
    <div className="row">
      <div className="g-order__fields g-order__fields--primary">
        <BankDetails register={register} validationErrors={validationErrors} />
      </div>

      <div className="g-order__fields g-order__fields--secondary">
        <BankAccountDetails register={register} validationErrors={validationErrors} />
      </div>
    </div>
  );
};

BankInfo.propTypes = {
  register: PropTypes.func,
  validationErrors: PropTypes.shape({
    'business-bank-name': PropTypes.shape({}),
    'time-with-business-bank': PropTypes.shape({}),
    'business-account-holder': PropTypes.shape({}),
    'business-account-number': PropTypes.shape({}),
    'business-sort-code': PropTypes.shape({}),
  }),
};

BankInfo.defaultProps = {
  register: null,
  validationErrors: null,
};

export default BankInfo;
