import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import PersonalDetails from './partials/PersonalDetails';
import AccommodationHistory from './partials/AccommodationHistory';
import CreditCheck from '../Finances/partials/CreditCheck';
import OptOut from '../Finances/partials/OptOut';

const PersonalInfo = ({ finalStep, showOptOut, register, unregister, validationErrors, verifyQuery }) => {
  return (
    <>
      <div className="row">
        <div className="g-order__fields g-order__fields--primary">
          <PersonalDetails register={register} validationErrors={validationErrors} verifyQuery={verifyQuery} />
        </div>

        <div className="g-order__fields g-order__fields--secondary">
          <AccommodationHistory register={register} unregister={unregister} validationErrors={validationErrors} />
        </div>
      </div>
      {finalStep && (
        <div className="row">
          <div className="g-order__check">
            <CreditCheck register={register} validationErrors={validationErrors} />
          </div>
          {showOptOut && (
            <div className="g-order__check">
              <OptOut register={register} validationErrors={validationErrors} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

PersonalInfo.propTypes = {
  showOptOut: PropTypes.bool,
  finalStep: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  validationErrors: PropTypes.shape({
    'customer-title': PropTypes.shape({}),
    'customer-first-name': PropTypes.shape({}),
    'customer-surname': PropTypes.shape({}),
    'customer-telephone': PropTypes.shape({}),
    'customer-email': PropTypes.shape({}),
    'customer-marital': PropTypes.shape({}),
    'customer-dependents': PropTypes.shape({}),
    'property-status': PropTypes.shape({}),
    credit_check: PropTypes.shape({}),
  }),
  verifyQuery: PropTypes.shape({
    email: PropTypes.string,
  }),
};

PersonalInfo.defaultProps = {
  showOptOut: false,
  finalStep: false,
  register: null,
  unregister: null,
  validationErrors: null,
  verifyQuery: null,
};

export default PersonalInfo;
