import React from 'react';
import PropTypes from 'prop-types';

// Component Imports
import PaymentsDetails from './partials/PaymentsDetails';
import BankAccountDetails from './partials/BankAccountDetails';
import CreditCheck from './partials/CreditCheck';
import OptOut from './partials/OptOut';

const Finances = ({ showOptOut, register, validationErrors }) => {
  return (
    <>
      <div className="row">
        <div className="g-order__fields g-order__fields--primary">
          <PaymentsDetails register={register} validationErrors={validationErrors} />
        </div>

        <div className="g-order__fields g-order__fields--secondary">
          <BankAccountDetails register={register} validationErrors={validationErrors} />
        </div>
      </div>
      <div className="row">
        <div className="g-order__check">
          <CreditCheck register={register} validationErrors={validationErrors} />
        </div>
        {showOptOut && (
          <div className="g-order__check">
            <OptOut register={register} validationErrors={validationErrors} />
          </div>
        )}
      </div>
    </>
  );
};

Finances.propTypes = {
  showOptOut: PropTypes.bool,
  register: PropTypes.func,
  validationErrors: PropTypes.shape({
    'gross-annual-salary': PropTypes.shape({}),
    'monthly-income': PropTypes.shape({}),
    'monthly-mortgage-rent': PropTypes.shape({}),
    'monthly-car-payments': PropTypes.shape({}),
    'monthly-other-outgoings': PropTypes.shape({}),
    'bank-name': PropTypes.shape({}),
    'bank-address': PropTypes.shape({}),
    'time-with-bank': PropTypes.shape({}),
    'bank-account-name': PropTypes.shape({}),
    'bank-account-number': PropTypes.shape({}),
    'bank-sort-code': PropTypes.shape({}),
    credit_check: PropTypes.shape({}),
  }),
};

Finances.defaultProps = {
  showOptOut: false,
  register: null,
  validationErrors: {},
};

export default Finances;
