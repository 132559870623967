import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import InputField from '../Forms/InputField';

import { emailValidationRegex } from '../Forms/utils';

const InspectCarForm = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    telephone: '',
    email: '',
    postcode: '',
    vehicleRegistration: '',
    vehicleMake: '',
    vehicleModel: '',
    message: '',
    authenticity_token: '',
    disabled: false,
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    if (state.disabled) {
      return;
    }

    setState({
      ...state,
      disabled: true,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    formRef.current.submit();
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmission)} noValidate ref={formRef} action={postURL} method="post">
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      <div className="row">
        <div className="g-inspection__primary">
          <InputField
            labelText="First Name"
            placeholder="First Name"
            id="first_name"
            type="text"
            onChange={(event) => setState({ ...state, firstName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.first_name}
            value={state.firstName}
            hiddenLabel={false}
          />

          <InputField
            labelText="Last Name"
            placeholder="Last Name"
            id="last_name"
            type="text"
            onChange={(event) => setState({ ...state, lastName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.last_name}
            value={state.lastName}
            hiddenLabel={false}
          />

          <InputField
            labelText="Phone"
            placeholder="Phone"
            id="contact_telephone"
            type="tel"
            onChange={(event) => setState({ ...state, telephone: event.target.value })}
            ref={register({
              required: true,
              validate: (value) => isValidNumber(value, 'GB'),
            })}
            hasError={!!errors.contact_telephone}
            value={state.telephone}
            hiddenLabel={false}
          />

          <InputField
            type="email"
            labelText="Email"
            id="contact_email"
            placeholder="Email"
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern: emailValidationRegex,
            })}
            hasError={!!errors.contact_email}
            value={state.email}
            hiddenLabel={false}
          />

          <InputField
            labelText="Postcode"
            placeholder="Postcode"
            id="postcode"
            type="text"
            onChange={(event) => setState({ ...state, postcode: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.postcode}
            value={state.postcode}
            hiddenLabel={false}
          />
        </div>

        <div className="g-inspection__secondary">
          <InputField
            labelText="Vehicle Registration"
            placeholder="Vehicle Registration"
            id="vehicle-registration"
            type="text"
            onChange={(event) => setState({ ...state, vehicleRegistration: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors['vehicle-registration']}
            value={state.vehicleRegistration}
            hiddenLabel={false}
          />

          <InputField
            labelText="Make of Vehicle"
            placeholder="Make of Vehicle"
            id="vehicle-make"
            type="text"
            onChange={(event) => setState({ ...state, vehicleMake: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors['vehicle-make']}
            value={state.vehicleMake}
            hiddenLabel={false}
          />

          <InputField
            labelText="Model of Vehicle"
            placeholder="Model of Vehicle"
            id="vehicle-model"
            type="text"
            onChange={(event) => setState({ ...state, vehicleModel: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors['vehicle-model']}
            value={state.vehicleModel}
            hiddenLabel={false}
          />

          <InputField
            labelText="Any notes or comments"
            placeholder="Any notes or comments"
            rows="4"
            id="notes"
            onChange={(event) => setState({ ...state, message: event.target.value })}
            value={state.message}
            variant="textarea"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="b-form-group">
            <div className="form-check-inline">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" name="bookenquire" value="1" defaultChecked />
                Make a booking
              </label>
            </div>

            <div className="form-check-inline">
              <label className="form-check-label">
                <input type="radio" className="form-check-input" name="bookenquire" value="2" />
                Make an enquiry
              </label>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="b-form-group">
            <button className="b-btn b-btn-block b-btn-green" type="submit" disabled={state.disabled}>
              {state.disabled ? 'Sending...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

InspectCarForm.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default InspectCarForm;
