import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

const OptOut = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAOptOut);

  return (
    <div className="custom-checkbox">
      <input
        className="custom-control-input"
        type="checkbox"
        id="opt-out"
        name="opt_out"
        onChange={(event) => dispatch({ type: 'SET_OPT_OUT', payload: event.target.checked })}
        ref={props.register()}
        checked={query.optOut}
      />
      <label className="custom-control-label" htmlFor="opt-out">
        We will sometimes send you emails about our very latest offers and relevant news, in accordance with{' '}
        <a
          href="https://ico.org.uk/media/for-organisations/documents/1555/direct-marketing-guidance.pdf"
          target="_blank"
          rel="noreferrer"
        >
          ICO P&ECR
        </a>{' '}
        soft opt in rules & our{' '}
        <a href="/privacy-policy" target="_blank">
          privacy policy
        </a>
        . Simply tick the box if you do not wish to receive them.
      </label>
    </div>
  );
};

OptOut.propTypes = {
  register: PropTypes.func.isRequired,
};

export default OptOut;
