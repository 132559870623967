import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../Forms/InputField';

import { emailValidationRegex } from '../../../Forms/utils';

const Verify = ({ register, validationErrors }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAVerify);

  const dispatchEmail = (event) => {
    dispatch({ type: 'SET_OCA_VERIFY_EMAIL', payload: event.target.value });
    dispatch({ type: 'SET_EMAIL', payload: event.target.value });
  };

  return (
    <div className="row">
      <div className="g-order__fields g-order__fields--primary">
        <InputField
          type="email"
          labelText="Email"
          id="verify-email"
          placeholder="Enter email so we can check for you in our system"
          onChange={(event) => dispatchEmail(event)}
          ref={register({
            required: true,
            pattern: emailValidationRegex,
          })}
          hasError={validationErrors['verify-email']}
          value={query.email}
        />
      </div>
    </div>
  );
};

Verify.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({ 'verify-email': PropTypes.shape({}) }).isRequired,
};

export default Verify;
