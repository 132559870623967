import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import InputField from '../../../../Forms/InputField';
import AddressLookup from '../../../../Forms/AddressLookup';

const EmployerAddress = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAEmploymentInfo);

  return (
    <>
      <AddressLookup
        searchLabel="Employer Postcode"
        id="employer-postcode"
        onChange={(event) =>
          dispatch({
            type: 'SET_EMPLOYER_POSTCODE',
            payload: event.target.value,
          })
        }
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['employer-postcode']}
        value={query.employerPostcode}
        actionName="EMPLOYER"
      />

      <InputField
        labelText="Employer Address line 1"
        id="employer-address-line-1"
        placeholder="Employer Address line 1"
        onChange={(event) =>
          dispatch({
            type: 'SET_EMPLOYER_ADDRESS_LINE_1',
            payload: event.target.value,
          })
        }
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['employer-address-line-1']}
        value={query.employerAddressLine1}
      />

      <InputField
        labelText="Employer Address line 2"
        id="employer-address-line-2"
        placeholder="Employer Address line 2"
        onChange={(event) =>
          dispatch({
            type: 'SET_EMPLOYER_ADDRESS_LINE_2',
            payload: event.target.value,
          })
        }
        value={query.employerAddressLine2}
      />

      <InputField
        labelText="Employer Address line 3"
        id="employer-address-line-3"
        placeholder="Employer Address line 3"
        onChange={(event) =>
          dispatch({
            type: 'SET_EMPLOYER_ADDRESS_LINE_3',
            payload: event.target.value,
          })
        }
        value={query.employerAddressLine3}
      />

      <InputField
        labelText="Employer Town"
        id="employer-town"
        placeholder="Employer Town"
        onChange={(event) => dispatch({ type: 'SET_EMPLOYER_TOWN', payload: event.target.value })}
        ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
        hasError={!!props.validationErrors['employer-town']}
        value={query.employerTown}
      />

      <InputField
        labelText="Employer County"
        id="employer-county"
        placeholder="Employer County"
        onChange={(event) => dispatch({ type: 'SET_EMPLOYER_COUNTY', payload: event.target.value })}
        ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
        hasError={!!props.validationErrors['employer-county']}
        value={query.employerCounty}
      />

      <InputField
        type="tel"
        labelText="Employer Telephone"
        id="employer-telephone"
        placeholder="Employer Telephone"
        onChange={(event) =>
          dispatch({
            type: 'SET_EMPLOYER_TELEPHONE',
            payload: event.target.value,
          })
        }
        ref={props.register({
          required: true,
          validate: (value) => isValidNumber(value, 'GB'),
        })}
        hasError={!!props.validationErrors['employer-telephone']}
        value={query.employerTelephone}
      />
    </>
  );
};

EmployerAddress.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'employer-postcode': PropTypes.shape({}),
    'employer-address-line-1': PropTypes.shape({}),
    'employer-town': PropTypes.shape({}),
    'employer-county': PropTypes.shape({}),
    'employer-telephone': PropTypes.shape({}),
  }).isRequired,
};

export default EmployerAddress;
