import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';

const BankAccountDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCABankInfo);

  return (
    <>
      <InputField
        labelText="Account Holder Name"
        id="business-account-holder"
        placeholder="Account Holder Name"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_ACCOUNT_HOLDER', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['business-account-holder']}
        value={query.businessAccountHolder}
      />

      <InputField
        type="number"
        labelText="Account Number"
        id="business-account-number"
        placeholder="Account Number"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_ACCOUNT_NUMBER', payload: event.target.value })}
        ref={props.register({ required: true, minLength: 7, maxLength: 12 })}
        hasError={!!props.validationErrors['business-account-number']}
        value={query.businessAccountNumber}
      />

      <InputField
        type="number"
        labelText="Sort Code"
        id="business-sort-code"
        placeholder="Sort Code"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_SORT_CODE', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['business-sort-code']}
        value={query.businessSortCode}
      />
    </>
  );
};

BankAccountDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'business-account-holder': PropTypes.shape({}),
    'business-account-number': PropTypes.shape({}),
    'business-sort-code': PropTypes.shape({}),
  }).isRequired,
};

export default BankAccountDetails;
