import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Component Imports
import Accordion from '../../../Accordion/Accordion';
import TimeSelector from '../../../Forms/TimeSelector';

// Partial Imports
import EmployerDetails from './partials/EmployerDetails';
import EmployerAddress from './partials/EmployerAddress';
import PreviousEmployerDetails from './partials/PreviousEmployerDetails';

const EmploymentInfo = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  // React Redux hooks
  const query = useSelector((state) => state.OCAEmploymentInfo);

  const toggleAccordion = (event) => {
    event.preventDefault();
    setIsOpen((wasOpen) => !wasOpen);
  };

  // Unregister specific accordion required fields if user selects over 5 years
  const handleYearChange = (event) => {
    if (event.target.value >= 5) {
      props.unregister([
        'previous-employment-status',
        'previous-employer',
        'previous-job-title',
        'previous-employer-postcode',
        'previous-employer-address-line-1',
        'previous-employer-town',
        'previous-employer-county',
        'previous-employer-telephone',
        'years-at-previous-employer',
        'months-at-previous-employer',
      ]);
    }
  };

  return (
    <div className="row">
      <div className="g-order__fields g-order__fields--primary">
        <EmployerDetails register={props.register} validationErrors={props.validationErrors} />

        {query.employmentStatus !== 'Retired' && (
          <EmployerAddress register={props.register} validationErrors={props.validationErrors} />
        )}
      </div>

      <div className="g-order__fields g-order__fields--secondary">
        <TimeSelector
          labelText={query.employmentStatus !== 'Retired' ? 'Time at employer' : 'Length of Time Retired'}
          id="time-at-current-employer"
          yearsDispatch="SET_YEARS_AT_EMPLOYER"
          monthsDispatch="SET_MONTHS_AT_EMPLOYER"
          required
          ref={props.register({ required: true })}
          hasError={props.validationErrors['time-at-current-employer']}
          value={[query.yearsAtEmployer, query.monthsAtEmployer]}
          onChange={(event) => handleYearChange(event)}
        />

        <Accordion
          id="five-years-employment-or-less"
          buttonText="If less than 5 years, please add previous employer"
          isOpen={isOpen || (query.yearsAtEmployer !== '' && query.yearsAtEmployer < 5)}
          onClick={toggleAccordion}
        >
          <PreviousEmployerDetails
            isValidated={query.yearsAtEmployer !== '' && query.yearsAtEmployer < 5}
            register={props.register}
            validationErrors={props.validationErrors}
          />
        </Accordion>
      </div>
    </div>
  );
};

EmploymentInfo.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  validationErrors: PropTypes.shape({
    'time-at-current-employer': PropTypes.shape({}),
    'employment-status': PropTypes.shape({}),
    employer: PropTypes.shape({}),
    'job-title': PropTypes.shape({}),
    'employer-postcode': PropTypes.shape({}),
    'employer-address-line-1': PropTypes.shape({}),
    'employer-town': PropTypes.shape({}),
    'employer-county': PropTypes.shape({}),
    'employer-telephone': PropTypes.shape({}),
    'previous-employment-status': PropTypes.shape({}),
    'previous-employer': PropTypes.shape({}),
    'previous-job-title': PropTypes.shape({}),
    'previous-employer-postcode': PropTypes.shape({}),
    'previous-employer-address-line-1': PropTypes.shape({}),
    'previous-employer-town': PropTypes.shape({}),
    'previous-employer-county': PropTypes.shape({}),
    'previous-employer-telephone': PropTypes.shape({}),
  }),
};

EmploymentInfo.defaultProps = {
  register: null,
  unregister: null,
  validationErrors: {},
};

export default EmploymentInfo;
