import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';
import InputSelect from '../../../../Forms/InputSelect';
import DateSelector from '../../../../Forms/DateSelector';
import { emailValidationRegex } from '../../../../Forms/utils';

const BusinessDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCACompanyInfo);

  return (
    <>
      <InputField
        labelText="Business name"
        id="business-name"
        placeholder="Business name"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_NAME', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['business-name']}
        value={query.businessName}
      />

      <div className="b-form-group">
        <InputSelect
          labelText="Select business type"
          id="business-type"
          onChange={(event) => dispatch({ type: 'SET_BUSINESS_TYPE', payload: event.target.value })}
          ref={props.register({ required: true })}
          hasError={!!props.validationErrors['business-type']}
          value={query.businessType}
        >
          <InputSelect.Option value="">Select business type...</InputSelect.Option>
          <InputSelect.Option value="Limited Company">Limited Company</InputSelect.Option>
          <InputSelect.Option value="Sole Trader">Sole Trader</InputSelect.Option>
          <InputSelect.Option value="Partnership Four Partners">Partnership (4+ partners)</InputSelect.Option>
          <InputSelect.Option value="Partnership Two Three Partners">Partnership (2-3 partners)</InputSelect.Option>
          <InputSelect.Option value="Limited Liability Partnership">Limited Liability Partnership</InputSelect.Option>
          <InputSelect.Option value="PLC">PLC</InputSelect.Option>
        </InputSelect>
      </div>

      <DateSelector
        id="business-established"
        groupText="Date established"
        dispatchAction="SET_DATE_BUSINESS_ESTABLISHED"
        required
        ref={props.register({ required: true })}
        hasError={props.validationErrors}
        value={query.businessEstablished}
      />

      <InputField
        labelText="Company number"
        id="company-number"
        placeholder="Company number"
        onChange={(event) => dispatch({ type: 'SET_COMPANY_NUMBER', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['company-number']}
        value={query.companyNumber}
      />

      <InputField
        labelText="VAT number"
        id="vat-number"
        placeholder="VAT number"
        onChange={(event) => dispatch({ type: 'SET_VAT_NUMBER', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['vat-number']}
        value={query.vatNumber}
      />

      <InputField
        type="number"
        min="0"
        labelText="Number of directors"
        id="number-of-directors"
        placeholder="Number of directors"
        onChange={(event) => dispatch({ type: 'SET_NUMBER_OF_DIRECTORS', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['number-of-directors']}
        value={query.numberOfDirectors}
      />

      <InputField
        type="email"
        labelText="Email"
        id="business-email"
        placeholder="Email"
        onChange={(event) => dispatch({ type: 'SET_BUSINESS_EMAIL', payload: event.target.value })}
        ref={props.register({
          required: true,
          pattern: emailValidationRegex,
        })}
        hasError={!!props.validationErrors['business-email']}
        value={query.businessEmail}
      />
    </>
  );
};

BusinessDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'business-name': PropTypes.shape({}),
    'business-type': PropTypes.shape({}),
    'company-number': PropTypes.shape({}),
    'vat-number': PropTypes.shape({}),
    'number-of-directors': PropTypes.shape({}),
    'business-email': PropTypes.shape({}),
  }).isRequired,
};

export default BusinessDetails;
