import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const SUVSearch = ({ channelSlug, makesPath }) => {
  const [state, setState] = useState({
    toggleActive: false,
    makes: [],
    models: [],
  });

  const makeRef = useRef(null);
  const modelRef = useRef(null);
  const categoryRef = useRef(null);

  const toggleSlider = () => {
    setState({ ...state, toggleActive: !state.toggleActive });
  };

  const getMakes = () => {
    axios
      .get('/car-leasing/bodytypes/suv/makes.json', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setState({ ...state, makes: response.data });
      });
  };

  const getModels = (event) => {
    axios
      .get(`/car-leasing/bodytypes/suv/makes/${event.target.value}/model_ranges.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setState({ ...state, models: response.data });
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let path = makesPath;

    if (state.toggleActive) {
      if (categoryRef.current.value === '') {
        path = '/car-leasing/bodytypes/suv';
      } else {
        path = `/car-leasing/bodytypes/${categoryRef.current.value}`;
      }
    } else if (makeRef.current.value !== '' || modelRef.current.value !== '') {
      path = `/${channelSlug}/${makeRef.current.value}${modelRef.current.value ? `/${modelRef.current.value}` : ''}`;
    }

    Turbolinks.visit(path);
  };

  useEffect(() => {
    getMakes();
  }, []);

  return (
    <div className="c-search-module__wrap">
      <h3 className="c-search-module__heading">Quick Search</h3>

      <div className="c-search-module__form">
        <div className="c-suv-switch">
          <label className="c-suv-switch__label">
            <span className="c-suv-switch__option">By make/model</span>
            <span className="c-suv-switch__toggler">
              <input className="c-suv-switch__input" type="checkbox" name="suvmakes" onChange={toggleSlider} />
              <span className={`c-suv-switch__slider${state.toggleActive ? ' c-suv-switch__slider--active' : ''}`} />
            </span>
            <span className="c-suv-switch__option">By type</span>
          </label>
        </div>

        {!state.toggleActive ? (
          <>
            <div className="b-form-group">
              <label className="b-form-label" htmlFor="make">
                Manufacturer
              </label>
              <select className="b-form-select" name="make" onChange={(event) => getModels(event)} ref={makeRef}>
                <option value="">Select manufacturer...</option>
                {state.makes.map((make) => (
                  <option value={make.slug} key={make.id}>
                    {make.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="b-form-group">
              <label className="b-form-label" htmlFor="model">
                Model
              </label>
              <select className="b-form-select suvmodel" name="model" ref={modelRef}>
                <option value="">Any model</option>
                {state.models.map((model) => (
                  <option value={model.slug} key={model.id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <div className="b-form-group">
            <label className="b-form-label" htmlFor="suvcategory">
              Category
            </label>
            <select className="b-form-select" name="suvcategory" ref={categoryRef}>
              <option value="">Select Category...</option>
              <option value="small-suv-or-crossover">Small SUV</option>
              <option value="medium-suv">Medium SUV</option>
              <option value="large-suv">Large SUV</option>
              <option value="luxury-suv">Luxury SUV</option>
              <option value="7-seater-suv">7 Seater SUV</option>
              <option value="sport-suv">Sport SUV</option>
            </select>
          </div>
        )}
        <button className="b-btn b-btn-green b-btn-block suv-model" onClick={handleSubmit}>
          Search
        </button>
      </div>
    </div>
  );
};

SUVSearch.propTypes = {
  channelSlug: PropTypes.string.isRequired,
  makesPath: PropTypes.string.isRequired,
};

export default SUVSearch;
