import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';

const PaymentsDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAFinances);

  return (
    <>
      <InputField
        type="number"
        min="0"
        labelText="Gross annual salary"
        id="gross-annual-salary"
        placeholder="Gross annual salary"
        onChange={(event) => dispatch({ type: 'SET_GROSS_ANNUAL_SALARY', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['gross-annual-salary']}
        value={query.grossAnnualSalary}
      />

      <InputField
        type="number"
        min="0"
        labelText="Monthly income"
        id="monthly-income"
        placeholder="Monthly income"
        onChange={(event) => dispatch({ type: 'SET_MONTHLY_INCOME', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['monthly-income']}
        value={query.monthlyIncome}
      />

      <InputField
        type="number"
        min="0"
        labelText="Monthly Mortgage/Rent"
        id="monthly-mortgage-rent"
        placeholder="Monthly Mortgage/Rent"
        onChange={(event) => dispatch({ type: 'SET_MONTHLY_MORTGAGE_RENT', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['monthly-mortgage-rent']}
        value={query.monthlyMortgageRent}
      />

      <InputField
        type="number"
        min="0"
        labelText="Monthly Car payments"
        id="monthly-car-payments"
        placeholder="Monthly Car payments"
        onChange={(event) => dispatch({ type: 'SET_MONTHLY_CAR_PAYMENTS', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['monthly-car-payments']}
        value={query.monthlyCarPayments}
      />

      <InputField
        type="number"
        min="0"
        labelText="Other monthly outgoings"
        id="monthly-other-outgoings"
        placeholder="Other monthly outgoings"
        onChange={(event) => dispatch({ type: 'SET_MONTHLY_OTHER_OUTGOINGS', payload: event.target.value })}
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['monthly-other-outgoings']}
        value={query.monthlyOtherOutgoings}
      />
    </>
  );
};

PaymentsDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'gross-annual-salary': PropTypes.shape({}),
    'monthly-income': PropTypes.shape({}),
    'monthly-mortgage-rent': PropTypes.shape({}),
    'monthly-car-payments': PropTypes.shape({}),
    'monthly-other-outgoings': PropTypes.shape({}),
  }).isRequired,
};

export default PaymentsDetails;
