import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';
import TimeSelector from '../../../../Forms/TimeSelector';
import AddressLookup from '../../../../Forms/AddressLookup';

const PropertyAddress = ({ id, actionName, isValidated, unregister, register, validationErrors }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAPersonalInfo);

  // Unregister specific accordion required fields if user selects over 5 years
  const handleYearChange = (event) => {
    if (event.target.value >= 5) {
      unregister([
        'postcode-secondary-address',
        'address-line-1-secondary-address',
        'town-secondary-address',
        'county-secondary-address',
        'years-at-secondary-address',
        'months-at-secondary-address',
      ]);
    }
  };

  return (
    <>
      <AddressLookup
        searchLabel="Postcode"
        id={`postcode-${id}`}
        onChange={(event) => dispatch({ type: `SET_${actionName}_POSTCODE`, payload: event.target.value })}
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors[`postcode-${id}`] : undefined}
        value={actionName === 'PRIMARY' ? query.primaryPostcode : query.secondaryPostcode}
        actionName={actionName}
      />

      <InputField
        labelText="Address line 1"
        id={`address-line-1-${id}`}
        placeholder="Address line 1"
        onChange={(event) => dispatch({ type: `SET_${actionName}_ADDRESS_LINE_1`, payload: event.target.value })}
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors[`address-line-1-${id}`] : undefined}
        value={actionName === 'PRIMARY' ? query.primaryAddressLine1 : query.secondaryAddressLine1}
      />

      <InputField
        labelText="Address line 2"
        id={`address-line-2-${id}`}
        placeholder="Address line 2"
        onChange={(event) => dispatch({ type: `SET_${actionName}_ADDRESS_LINE_2`, payload: event.target.value })}
        value={actionName === 'PRIMARY' ? query.primaryAddressLine2 : query.secondaryAddressLine2}
      />

      <InputField
        labelText="Address line 3"
        id={`address-line-3-${id}`}
        placeholder="Address line 3"
        onChange={(event) => dispatch({ type: `SET_${actionName}_ADDRESS_LINE_3`, payload: event.target.value })}
        value={actionName === 'PRIMARY' ? query.primaryAddressLine3 : query.secondaryAddressLine3}
      />

      <InputField
        labelText="Town"
        id={`town-${id}`}
        placeholder="Town"
        onChange={(event) => dispatch({ type: `SET_${actionName}_TOWN`, payload: event.target.value })}
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors[`town-${id}`] : undefined}
        value={actionName === 'PRIMARY' ? query.primaryTown : query.secondaryTown}
      />

      <InputField
        labelText="County"
        id={`county-${id}`}
        placeholder="County"
        onChange={(event) => dispatch({ type: `SET_${actionName}_COUNTY`, payload: event.target.value })}
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors[`county-${id}`] : undefined}
        value={actionName === 'PRIMARY' ? query.primaryCounty : query.secondaryCounty}
      />

      <TimeSelector
        labelText="Time at address"
        id={id}
        yearsDispatch={`SET_YEARS_AT_${actionName}_ADDRESS`}
        monthsDispatch={`SET_MONTHS_AT_${actionName}_ADDRESS`}
        required
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? validationErrors : undefined}
        value={[
          actionName === 'PRIMARY' ? query.yearsAtPrimaryAddress : query.yearsAtSecondaryAddress,
          actionName === 'PRIMARY' ? query.monthsAtPrimaryAddress : query.monthsAtSecondaryAddress,
        ]}
        onChange={actionName === 'PRIMARY' ? handleYearChange : undefined}
      />
    </>
  );
};

PropertyAddress.propTypes = {
  id: PropTypes.string.isRequired,
  isValidated: PropTypes.bool.isRequired,
  actionName: PropTypes.string,
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({}).isRequired,
};

PropertyAddress.defaultProps = {
  actionName: '',
};

export default PropertyAddress;
