import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Style imports
import './ImageCard.scss';

const ImageCard = ({ image }) => {
  const classes = classNames({
    'c-image-upload c-image-upload--edit': true,
    'c-image-upload--error': image.error,
    'c-image-upload--progress': image.uploading,
  });

  return (
    <div className={classes}>
      <div className="c-image-upload__asset">
        {image.error && (
          <button
            className="c-image-upload__reload"
            onClick={(e) => {
              e.preventDefault();
              image.refresh();
            }}
          >
            <span className="b-accessible">Retry upload</span>
          </button>
        )}

        <img className="c-image-upload__img" src={image.source} alt="" aria-hidden="true" />
      </div>

      <div className="c-image-upload__meta">
        <p className="c-image-upload__name">{image.name}</p>
        <p className="c-image-upload__size">{image.size}</p>
      </div>

      <button
        className="c-image-upload__delete"
        onClick={(e) => {
          e.preventDefault();
          image.remove();
        }}
      >
        <span className="b-accessible">Remove image</span>
      </button>

      <span className="c-image-upload__reorder" />
    </div>
  );
};

ImageCard.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    source: PropTypes.string,
    uploading: PropTypes.bool,
    error: PropTypes.bool,
    refresh: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
};

export default ImageCard;
