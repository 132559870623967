/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

const StepTrackerPagination = ({ pages }) => {
  // React Redux hooks
  const query = useSelector((state) => state.OCAPersonalInfo);

  return (
    <div className="g-order__progress">
      {pages &&
        pages.map((item, index) => {
          const classes = classNames({
            'g-order__step': true,
            'g-order__step--active': query.currentStep - 1 === index,
          });

          return (
            <div className={classes} key={index}>
              <span>{index + 1}</span>
              {item.name}
            </div>
          );
        })}
    </div>
  );
};

StepTrackerPagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default StepTrackerPagination;
