import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StepZilla from 'react-stepzilla';
import { useDispatch } from 'react-redux';

// Component Imports
import StepTrackerPage from './StepTrackerPage';

const StepTracker = ({
  steps,
  postURL,
  channelID,
  offerId,
  initialPayment,
  optionIds,
  derivativeId,
  term,
  mileage,
  maintenanceSelected,
  verifyStep,
  verifyPostUrl,
}) => {
  // React Redux hooks
  const dispatch = useDispatch();

  const resetReduxState = () => {
    dispatch({ type: 'RESET_OCA_VERIFY_STATE' });
    dispatch({ type: 'RESET_OCA_CREDIT_CHECK' });
    dispatch({ type: 'RESET_OCA_PERSONAL_INFO_STATE' });
    dispatch({ type: 'RESET_OCA_FINANCES_STATE' });
    dispatch({ type: 'RESET_OCA_EMPLOYMENT_INFO_STATE' });
    dispatch({ type: 'RESET_OCA_COMPANY_INFO_STATE' });
    dispatch({ type: 'RESET_OCA_BANK_INFO_STATE' });
  };

  useEffect(() => {
    document.addEventListener('turbolinks:load', resetReduxState());

    return () => {
      document.removeEventListener('turbolinks:load', resetReduxState());
    };
  }, []);

  const stepsArray = [];
  const propSteps = steps;

  for (let i = 0; i < propSteps.length; i += 1) {
    const step = {
      name: propSteps[i].name,
      component: (
        <StepTrackerPage
          step={i}
          component={propSteps[i].component}
          backDisabled={propSteps[i].backDisabled}
          isLast={i === propSteps.length - 1}
          isFirst={i === 0}
          heading={propSteps[i].heading}
          pages={steps}
          postURL={postURL}
          channelID={channelID}
          offerId={offerId}
          derivativeId={derivativeId}
          term={term}
          mileage={mileage}
          initialPayment={initialPayment}
          optionIds={optionIds}
          maintenanceSelected={maintenanceSelected}
          verifyStep={verifyStep}
          verifyPostUrl={verifyPostUrl}
        />
      ),
    };

    stepsArray.push(step);
  }

  useEffect(() => {
    const pageURL = window.location.href;
    const dealID = pageURL.substring(pageURL.lastIndexOf('/') + 1);

    dispatch({
      type: 'SET_DEAL_ID',
      payload: dealID,
    });
  }, [dispatch]);

  return (
    <div className="application g-order__form bootstrap-form">
      <StepZilla
        showSteps={false}
        steps={stepsArray}
        stepsNavigation={false}
        showNavigation={false}
        preventEnterSubmission
      />
    </div>
  );
};

StepTracker.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      heading: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  derivativeId: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  mileage: PropTypes.number.isRequired,
  optionIds: PropTypes.arrayOf(PropTypes.string),
  maintenanceSelected: PropTypes.bool.isRequired,
  initialPayment: PropTypes.string.isRequired,
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  verifyStep: PropTypes.bool.isRequired,
  verifyPostUrl: PropTypes.string.isRequired,
};

StepTracker.defaultProps = {
  optionIds: [],
};

export default StepTracker;
