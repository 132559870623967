import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// Component Imports
import './InputSpinner.scss';

const InputSpinner = ({ id, labelText, initialValue, min, max, step, updateValue }) => {
  const [inputValue, setInputValue] = useState(initialValue || 0);

  // React Redux hooks
  const dispatch = useDispatch();

  const dispatchValue = (value) => {
    dispatch({
      type: updateValue,
      payload: value,
    });
  };

  const decreaseValue = (event) => {
    event.preventDefault();

    const newValue = inputValue - step;

    if (newValue <= min) {
      setInputValue(min);
      dispatchValue(min);
    } else {
      setInputValue(newValue);
      dispatchValue(newValue);
    }
  };

  const increaseValue = (event) => {
    event.preventDefault();

    const newValue = inputValue + step;

    if (newValue >= max) {
      setInputValue(max);
      dispatchValue(max);
    } else {
      setInputValue(newValue);
      dispatchValue(newValue);
    }
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value);

    const newValue = Number.isNaN(value) ? '' : value;

    if (newValue <= max) {
      setInputValue(newValue);
      dispatchValue(newValue === '' ? 0 : newValue);
    } else {
      setInputValue(max);
      dispatchValue(max);
    }
  };

  return (
    <div className="input-spinner">
      <label className="input-spinner__label" htmlFor={id}>
        {labelText}
      </label>

      <div className="input-spinner__controls">
        <button className="input-spinner__button" onClick={decreaseValue} aria-controls={id}>
          <span className="sr-only">Decrease value</span>
        </button>

        <input
          className="input-spinner__input"
          id={id}
          name={id}
          type="number"
          value={inputValue}
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
        />

        <button
          className="input-spinner__button input-spinner__button--plus"
          onClick={increaseValue}
          aria-controls={id}
        >
          <span className="sr-only">Increase value</span>
        </button>
      </div>
    </div>
  );
};

InputSpinner.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  initialValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  updateValue: PropTypes.string.isRequired,
};

export default InputSpinner;
