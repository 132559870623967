import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isValidNumber } from 'libphonenumber-js';

// Component Imports
import InputField from '../../../../Forms/InputField';
import InputSelect from '../../../../Forms/InputSelect';
import TimeSelector from '../../../../Forms/TimeSelector';
import AddressLookup from '../../../../Forms/AddressLookup';

const PreviousEmployerDetails = ({ isValidated, register, validationErrors }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAEmploymentInfo);

  return (
    <>
      <div className="b-form-group">
        <InputSelect
          labelText="Previous employment status"
          id="previous-employment-status"
          onChange={(event) =>
            dispatch({
              type: 'SET_PREVIOUS_EMPLOYMENT_STATUS',
              payload: event.target.value,
            })
          }
          ref={isValidated ? register({ required: true }) : undefined}
          hasError={isValidated ? !!validationErrors['previous-employment-status'] : undefined}
          value={query.previousEmploymentStatus}
        >
          <InputSelect.Option value="">Select Previous employment status</InputSelect.Option>
          <InputSelect.Option value="Employed">Employed</InputSelect.Option>
          <InputSelect.Option value="Self Employed">Self Employed</InputSelect.Option>
          <InputSelect.Option value="Part time">Part Time</InputSelect.Option>
          <InputSelect.Option value="Retired">Retired</InputSelect.Option>
        </InputSelect>
      </div>

      <InputField
        labelText="Previous employer"
        id="previous-employer"
        placeholder="Previous employer"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-employer'] : undefined}
        value={query.previousEmployer}
      />

      <InputField
        labelText="Job title"
        id="previous-job-title"
        placeholder="Job title"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_JOB_TITLE',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-job-title'] : undefined}
        value={query.previousJobTitle}
      />

      <AddressLookup
        searchLabel="Employer Postcode"
        id="previous-employer-postcode"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_POSTCODE',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-employer-postcode'] : undefined}
        value={query.previousEmployerPostcode}
        actionName="PREVIOUS_EMPLOYER"
      />

      <InputField
        labelText="Employer Address line 1"
        id="previous-employer-address-line-1"
        placeholder="Employer Address line 1"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_1',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-employer-address-line-1'] : undefined}
        value={query.previousEmployerAddressLine1}
      />

      <InputField
        labelText="Employer Address line 2"
        id="previous-employer-address-line-2"
        placeholder="Employer Address line 2"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_2',
            payload: event.target.value,
          })
        }
        value={query.previousEmployerAddressLine2}
      />

      <InputField
        labelText="Employer Address line 3"
        id="previous-employer-address-line-3"
        placeholder="Employer Address line 3"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_ADDRESS_LINE_3',
            payload: event.target.value,
          })
        }
        value={query.previousEmployerAddressLine3}
      />

      <InputField
        labelText="Employer Town"
        id="previous-employer-town"
        placeholder="Employer Town"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_TOWN',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-employer-town'] : undefined}
        value={query.previousEmployerTown}
      />

      <InputField
        labelText="Employer County"
        id="previous-employer-county"
        placeholder="Employer County"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_COUNTY',
            payload: event.target.value,
          })
        }
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-employer-county'] : undefined}
        value={query.previousEmployerCounty}
      />

      <InputField
        type="tel"
        labelText="Employer Telephone"
        id="previous-employer-telephone"
        placeholder="Employer Telephone"
        onChange={(event) =>
          dispatch({
            type: 'SET_PREVIOUS_EMPLOYER_TELEPHONE',
            payload: event.target.value,
          })
        }
        ref={
          isValidated
            ? register({
                required: true,
                validate: (value) => isValidNumber(value, 'GB'),
              })
            : undefined
        }
        hasError={isValidated ? !!validationErrors['previous-employer-telephone'] : undefined}
        value={query.previousEmployerTelephone}
      />

      <TimeSelector
        labelText="Time at employer"
        id="previous-employer"
        yearsDispatch="SET_YEARS_AT_PREVIOUS_EMPLOYER"
        monthsDispatch="SET_MONTHS_AT_PREVIOUS_EMPLOYER"
        required
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? validationErrors['previous-employer'] : undefined}
        value={[query.yearsAtPreviousEmployer, query.monthsAtPreviousEmployer]}
      />
    </>
  );
};

PreviousEmployerDetails.propTypes = {
  isValidated: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'previous-employment-status': PropTypes.shape({}),
    'previous-employer': PropTypes.shape({}),
    'previous-job-title': PropTypes.shape({}),
    'previous-employer-postcode': PropTypes.shape({}),
    'previous-employer-address-line-1': PropTypes.shape({}),
    'previous-employer-town': PropTypes.shape({}),
    'previous-employer-county': PropTypes.shape({}),
    'previous-employer-telephone': PropTypes.shape({}),
  }).isRequired,
};

export default PreviousEmployerDetails;
