import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Component Imports
import InputSelect from '../../../../Forms/InputSelect';
import PropertyAddress from './PropertyAddress';
import Accordion from '../../../../Accordion/Accordion';

const AccommodationDetails = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAPersonalInfo);

  const toggleAccordion = (event) => {
    event.preventDefault();
    setIsOpen((wasOpen) => !wasOpen);
  };

  return (
    <>
      <div className="b-form-group">
        <InputSelect
          labelText="Select Property Status"
          id="property-status"
          onChange={(event) => dispatch({ type: 'SET_PROPERTY_STATUS', payload: event.target.value })}
          ref={props.register({ required: true })}
          hasError={!!props.validationErrors['property-status']}
          value={query.propertyStatus}
        >
          <InputSelect.Option value="">Select Property Status</InputSelect.Option>
          <InputSelect.Option value="Owned Outright">Owned Outright</InputSelect.Option>
          <InputSelect.Option value="Owned With Mortgage">Owned with Mortgage</InputSelect.Option>
          <InputSelect.Option value="Rented">Rented</InputSelect.Option>
          <InputSelect.Option value="Living With Parents">Living with parents</InputSelect.Option>
        </InputSelect>
      </div>

      <PropertyAddress
        id="primary-address"
        actionName="PRIMARY"
        isValidated
        register={props.register}
        unregister={props.unregister}
        validationErrors={props.validationErrors}
      />

      <Accordion
        id="five-years-living-or-less"
        buttonText="If less than 5 years, please add previous address"
        isOpen={isOpen || (query.yearsAtPrimaryAddress !== '' && query.yearsAtPrimaryAddress < 5)}
        onClick={toggleAccordion}
      >
        <PropertyAddress
          id="secondary-address"
          actionName="SECONDARY"
          isValidated={query.yearsAtPrimaryAddress !== '' && query.yearsAtPrimaryAddress < 5}
          register={props.register}
          unregister={props.unregister}
          validationErrors={props.validationErrors}
        />
      </Accordion>
    </>
  );
};

AccommodationDetails.propTypes = {
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'property-status': PropTypes.shape({}),
  }).isRequired,
};

export default AccommodationDetails;
