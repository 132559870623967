import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputSelect from '../../../../Forms/InputSelect';
import InputField from '../../../../Forms/InputField';

const EmployerDetails = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCAEmploymentInfo);

  return (
    <>
      <div className="b-form-group">
        <InputSelect
          labelText="Employment status"
          id="employment-status"
          onChange={(event) =>
            dispatch({
              type: 'SET_EMPLOYMENT_STATUS',
              payload: event.target.value,
            })
          }
          ref={props.register({ required: true })}
          hasError={!!props.validationErrors['employment-status']}
          value={query.employmentStatus}
        >
          <InputSelect.Option value="">Employment status</InputSelect.Option>
          <InputSelect.Option value="Employed">Employed</InputSelect.Option>
          <InputSelect.Option value="Self Employed">Self Employed</InputSelect.Option>
          <InputSelect.Option value="Part time">Part Time</InputSelect.Option>
          <InputSelect.Option value="Retired">Retired</InputSelect.Option>
        </InputSelect>
      </div>

      {query.employmentStatus !== 'Retired' && (
        <>
          <InputField
            labelText="Employer"
            id="employer"
            placeholder="Employer"
            onChange={(event) => dispatch({ type: 'SET_EMPLOYER', payload: event.target.value })}
            ref={props.register({ required: true })}
            hasError={!!props.validationErrors.employer}
            value={query.employer}
          />

          <InputField
            labelText="Job title"
            id="job-title"
            placeholder="Job title"
            onChange={(event) => dispatch({ type: 'SET_JOB_TITLE', payload: event.target.value })}
            ref={props.register({ required: true })}
            hasError={!!props.validationErrors['job-title']}
            value={query.jobTitle}
          />
        </>
      )}
    </>
  );
};

EmployerDetails.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'employment-status': PropTypes.shape({}),
    employer: PropTypes.shape({}),
    'job-title': PropTypes.shape({}),
  }).isRequired,
};

export default EmployerDetails;
