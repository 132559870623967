import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from 'axios';

// Component Imports
import StepTrackerControls from './StepTrackerControls';
import StepTrackerPagination from './StepTrackerPagination';

const StepTrackerPage = ({
  heading,
  pages,
  postURL,
  channelID,
  initialPayment,
  offerId,
  derivativeId,
  term,
  mileage,
  optionIds,
  maintenanceSelected,
  verifyStep,
  verifyPostUrl,
  isFirst,
  isLast,
  jumpToStep,
  step,
  component,
  backDisabled,
}) => {
  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    errors,
    unregister,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [hasErrors, setHasErrors] = useState(false);
  const [emailVerificationError, setEmailVerificationError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  // React Redux hooks
  const dispatch = useDispatch();
  const verifyQuery = useSelector((state) => state.OCAVerify);
  const personalQuery = useSelector((state) => state.OCAPersonalInfo);
  const financesQuery = useSelector((state) => state.OCAFinances);
  const employmentQuery = useSelector((state) => state.OCAEmploymentInfo);
  const companyQuery = useSelector((state) => state.OCACompanyInfo);
  const bankQuery = useSelector((state) => state.OCABankInfo);
  const creditCheckQuery = useSelector((state) => state.OCACreditCheck);
  const optOutQuery = useSelector((state) => state.OCAOptOut);

  const formRef = useRef(null);

  const nextClicked = () => {
    jumpToStep(step + 1);

    dispatch({
      type: 'SET_OCA_CURRENT_STEP',
      payload: !isLast ? personalQuery.currentStep + 1 : personalQuery.currentStep,
    });

    window.scrollTo(0, 0);
  };

  const handleFormSubmission = async () => {
    if (isLast) {
      if (isSubmitting) return;

      const formData = {
        personalQuery,
        financesQuery,
        employmentQuery,
        companyQuery,
        bankQuery,
        creditCheckQuery,
        optOutQuery,
      };

      await axios
        .post(postURL, {
          formData,
          authenticity_token: document.getElementsByName('csrf-token')[0].content,
          channel_id: channelID,
          offer_id: offerId,
          derivative_id: derivativeId,
          term,
          mileage,
          initial_payment: initialPayment,
          option_ids: optionIds,
          maintenance_selected: maintenanceSelected,
        })
        .then((response) => {
          if (response.data.errors && response.data.errors.length > 0) {
            setHasErrors(true);
          } else {
            setIsSubmitted(true);
            Turbolinks.visit(response.data.successURL);
          }
        });
    } else if (isFirst && verifyStep) {
      await axios
        .get(verifyPostUrl, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          params: {
            email: verifyQuery.email,
          },
        })
        .then(() => {
          nextClicked();
        })
        .catch((error) => {
          setEmailVerificationError(error.response.data.message);
        });
    } else {
      nextClicked();
    }
  };

  const backClicked = () => {
    jumpToStep(step - 1);

    dispatch({
      type: 'SET_OCA_CURRENT_STEP',
      payload: personalQuery.currentStep - 1,
    });

    window.scrollTo(0, 0);
  };

  return (
    <>
      <StepTrackerPagination pages={pages} />

      <div className="g-order__header">
        <h1 className="g-order__title">{heading}</h1>
        <p className="g-order__subtitle">
          Before we can begin processing your order, we&apos;ll need to take some details. This should only take a few
          minutes
        </p>
      </div>

      <form onSubmit={handleSubmit(handleFormSubmission)} ref={formRef} noValidate>
        <div className="step-tracker__step">
          {React.cloneElement(component, {
            register,
            unregister,
            validationErrors: errors,
            verifyQuery,
          })}
        </div>

        <StepTrackerControls
          isLast={isLast}
          pages={pages}
          step={step}
          backClicked={backClicked}
          backDisabled={backDisabled}
          hasErrors={hasErrors}
          emailVerificationError={verifyStep && emailVerificationError}
          submitDisabled={isSubmitting || isSubmitted}
        />
      </form>
    </>
  );
};

StepTrackerPage.propTypes = {
  heading: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  component: PropTypes.node.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      heading: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  backDisabled: PropTypes.bool,
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
  initialPayment: PropTypes.string.isRequired,
  jumpToStep: PropTypes.func,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  derivativeId: PropTypes.number.isRequired,
  term: PropTypes.number.isRequired,
  mileage: PropTypes.number.isRequired,
  optionIds: PropTypes.arrayOf(PropTypes.string),
  maintenanceSelected: PropTypes.bool.isRequired,
  verifyStep: PropTypes.bool.isRequired,
  verifyPostUrl: PropTypes.string.isRequired,
};

StepTrackerPage.defaultProps = {
  backDisabled: false,
  jumpToStep: null,
  optionIds: null,
};

export default StepTrackerPage;
