import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';

const ModelSearch = ({ channelSlug, makesPath, makes, grosvenor, id }) => {
  const [models, setModels] = useState([]);
  const [controlHeld, setControlHeld] = useState(false);

  const manufacturerRef = useRef(null);
  const modelRef = useRef(null);

  id = grosvenor ? 'grosvenor' : id;

  const handleManufacturerChange = (event) => {
    axios
      .get(`/${channelSlug}/${event.target.value}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setModels(response.data);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let path = '';

    if (manufacturerRef.current.value === '' && modelRef.current.value === '') {
      path = makesPath;
    } else {
      path = `/${channelSlug}/${manufacturerRef.current.value}${modelRef.current.value ? `/${modelRef.current.value}` : ''}`;
    }

    if (controlHeld) {
      window.open(`${path}`);
    } else if (!controlHeld) {
      Turbolinks.visit(path);
    }
  };

  const handleKeyDown = (event) => {
    // Check if Control or Apple Cmd key is being held
    if (event.which === 17 || event.which === 224 || event.which === 91 || event.which === 93) {
      setControlHeld(true);
    }
  };

  const handleKeyUp = (event) => {
    if (event.which === 17 || event.which === 224 || event.which === 91 || event.which === 93) {
      setControlHeld(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <>
      <div
        className={classNames('b-form-group', {
          'c-search__form-group c-search__form-group--manufacturer': !grosvenor,
        })}
      >
        <label className="sr-only" htmlFor={`model-search-manufacturer-${id}`}>
          Manufacturer
        </label>
        <select
          className="c-search__form-control c-search__form-select"
          name={`model-search-manufacturer-${id}`}
          id={`model-search-manufacturer-${id}`}
          onChange={handleManufacturerChange}
          ref={manufacturerRef}
        >
          <option value="">Any make...</option>
          {makes &&
            makes.map((make) => (
              <option value={make.slug} key={make.slug}>
                {make.name}
              </option>
            ))}
        </select>
      </div>

      <div className={classNames('b-form-group', { 'c-search__form-group c-search__form-group--model': !grosvenor })}>
        <label className="sr-only" htmlFor={`model-search-model-${id}`}>
          Model
        </label>
        <select
          className="c-search__form-control c-search__form-select"
          name={`model-search-model-${id}`}
          id={`model-search-model-${id}`}
          ref={modelRef}
        >
          <option value="">Any model...</option>
          {models &&
            models.map((model) => (
              <option value={model.slug} key={model.slug}>
                {model.name}
              </option>
            ))}
        </select>
      </div>

      <div
        className={classNames({
          'c-search__form-group c-search__form-group--submit': !grosvenor,
          'c-grosvenor-search-module__btn': grosvenor,
        })}
      >
        <button
          className={classNames({
            'c-search__button': !grosvenor,
            'b-btn b-btn-block b-btn-shadowless b-btn-green': grosvenor,
          })}
          onClick={handleSubmit}
        >
          Search {channelSlug === 'car-leasing' ? 'Cars' : 'Vans'}
        </button>
      </div>
    </>
  );
};

ModelSearch.propTypes = {
  id: PropTypes.string.isRequired,
  channelSlug: PropTypes.string.isRequired,
  makes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
  makesPath: PropTypes.string.isRequired,
  grosvenor: PropTypes.bool,
};

ModelSearch.defaultProps = {
  grosvenor: false,
};

export default ModelSearch;
