import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';

// Component Imports
import InputField from '../Forms/InputField';

import { emailValidationRegex } from '../Forms/utils';

const SignUpMobile = ({ postURL, channelID }) => {
  const [state, setState] = useState({
    fullName: '',
    email: '',
    authenticity_token: '',
  });

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    errors,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const formRef = useRef(null);

  const handleFormSubmission = () => {
    setState({
      ...state,
      authenticity_token: document.querySelector('[name=csrf-token]').content,
    });
    Cookies.set('hide_sign_up', 'true', { expires: 7 });
    formRef.current.submit();
  };

  return (
    <form
      className="bootstrap-form"
      onSubmit={handleSubmit(handleFormSubmission)}
      noValidate
      ref={formRef}
      action={postURL}
      method="post"
    >
      <input type="hidden" name="authenticity_token" value={state.authenticity_token} />
      <input type="hidden" name="channel_id" value={channelID} />

      <div className="row">
        <div className="col-12 col-sm-6">
          <InputField
            labelText="Full name *"
            placeholder="Full name"
            id="full_name"
            type="text"
            onChange={(event) => setState({ ...state, fullName: event.target.value })}
            ref={register({ required: true })}
            hasError={!!errors.full_name}
            value={state.fullName}
            hiddenLabel={false}
          />
        </div>

        <div className="col-12 col-sm-6">
          <InputField
            type="email"
            labelText="Email *"
            id="email"
            placeholder="Email address"
            onChange={(event) => setState({ ...state, email: event.target.value })}
            ref={register({
              required: true,
              pattern: emailValidationRegex,
            })}
            hasError={!!errors.email}
            value={state.email}
            hiddenLabel={false}
          />
        </div>

        <div className="col-12">
          <p className="b-text-xs">
            <span className="b-info" />
            By completing this form you are agreeing to receive marketing communication from us (relating to our car
            tips, special offers, news, and vehicle reviews, and other relevant information), and if you have previously
            unsubscribed, you are allowing us to resubscribe you to the newsletter. Please see our data privacy policy{' '}
            <a href="/privacy-policy">here</a>.
          </p>
        </div>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <button type="submit" className="b-btn b-btn-green b-btn-block" disabled={isSubmitting}>
            {isSubmitting ? 'Loading...' : 'Sign up'}
          </button>
        </div>
      </div>
    </form>
  );
};

SignUpMobile.propTypes = {
  postURL: PropTypes.string.isRequired,
  channelID: PropTypes.string.isRequired,
};

export default SignUpMobile;
