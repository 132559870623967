import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { isValidNumber } from 'libphonenumber-js';

// Component Import
import Accordion from '../../../../Accordion/Accordion';
import InputField from '../../../../Forms/InputField';
import InputSelect from '../../../../Forms/InputSelect';
import AddressLookup from '../../../../Forms/AddressLookup';
import TimeSelector from '../../../../Forms/TimeSelector';
import PreviousPremisesAddress from './PreviousPremisesAddress';

const PremisesHistory = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCACompanyInfo);

  const toggleAccordion = (event) => {
    event.preventDefault();
    setIsOpen((wasOpen) => !wasOpen);
  };

  // Unregister specific accordion required fields if user selects over 5 years
  const handleYearChange = (event) => {
    if (event.target.value >= 5) {
      props.unregister([
        'previous-company-postcode',
        'previous-company-address-line-1',
        'previous-company-town',
        'previous-company-county',
        'years-at-time-at-previous-company-address',
        'months-at-time-at-previous-company-address',
      ]);
    }
  };

  return (
    <>
      <h3 className="hidden-sm-up">Company address *</h3>

      <AddressLookup
        searchLabel="Company Postcode"
        id="company-postcode"
        onChange={(event) =>
          dispatch({
            type: 'SET_COMPANY_POSTCODE',
            payload: event.target.value,
          })
        }
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['company-postcode']}
        value={query.companyPostcode}
        actionName="COMPANY"
      />

      <InputField
        labelText="Address line 1"
        id="company-address-line-1"
        placeholder="Address line 1"
        onChange={(event) =>
          dispatch({
            type: 'SET_COMPANY_ADDRESS_LINE_1',
            payload: event.target.value,
          })
        }
        ref={props.register({ required: true })}
        hasError={!!props.validationErrors['company-address-line-1']}
        value={query.companyAddressLine1}
      />

      <InputField
        labelText="Address line 2"
        id="company-address-line-2"
        placeholder="Address line 2"
        onChange={(event) =>
          dispatch({
            type: 'SET_COMPANY_ADDRESS_LINE_2',
            payload: event.target.value,
          })
        }
        value={query.companyAddressLine2}
      />

      <InputField
        labelText="Address line 3"
        id="company-address-line-3"
        placeholder="Address line 3"
        onChange={(event) =>
          dispatch({
            type: 'SET_COMPANY_ADDRESS_LINE_3',
            payload: event.target.value,
          })
        }
        value={query.companyAddressLine3}
      />

      <InputField
        labelText="Town"
        id="company-town"
        placeholder="Town"
        onChange={(event) => dispatch({ type: 'SET_COMPANY_TOWN', payload: event.target.value })}
        ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
        hasError={!!props.validationErrors['company-town']}
        value={query.companyTown}
      />

      <InputField
        labelText="County"
        id="company-county"
        placeholder="County"
        onChange={(event) => dispatch({ type: 'SET_COMPANY_COUNTY', payload: event.target.value })}
        ref={props.register({ required: true, pattern: /^[A-Za-z\s-]+$/i })}
        hasError={!!props.validationErrors['company-county']}
        value={query.companyCounty}
      />

      <div className="b-form-group">
        <InputSelect
          labelText="Select Property Status"
          id="company-property-status"
          onChange={(event) =>
            dispatch({
              type: 'SET_COMPANY_PROPERTY_STATUS',
              payload: event.target.value,
            })
          }
          ref={props.register({ required: true })}
          hasError={!!props.validationErrors['company-property-status']}
          value={query.companyPropertyStatus}
        >
          <InputSelect.Option value="Owned Outright">Owned Outright</InputSelect.Option>
          <InputSelect.Option value="Owned With Mortgage">Owned with Mortgage</InputSelect.Option>
          <InputSelect.Option value="Rented">Rented</InputSelect.Option>
        </InputSelect>
      </div>

      <TimeSelector
        labelText="Time at address"
        id="time-at-company-address"
        yearsDispatch="SET_YEARS_AT_COMPANY_ADDRESS"
        monthsDispatch="SET_MONTHS_AT_COMPANY_ADDRESS"
        required
        ref={props.register({ required: true })}
        hasError={props.validationErrors['time-at-company-address']}
        value={[query.yearsAtCompanyAddress, query.monthsAtCompanyAddress]}
        onChange={(event) => handleYearChange(event)}
      />

      <InputField
        type="tel"
        labelText="Telephone"
        id="company-telephone"
        placeholder="Telephone"
        onChange={(event) =>
          dispatch({
            type: 'SET_COMPANY_TELEPHONE',
            payload: event.target.value,
          })
        }
        ref={props.register({
          required: true,
          validate: (value) => isValidNumber(value, 'GB'),
        })}
        hasError={!!props.validationErrors['company-telephone']}
        value={query.companyTelephone}
      />

      <Accordion
        id="five-years-premises-or-less"
        buttonText="If less than 5 years, please add previous address"
        isOpen={isOpen || (query.yearsAtCompanyAddress !== '' && query.yearsAtCompanyAddress < 5)}
        onClick={toggleAccordion}
      >
        <PreviousPremisesAddress
          isValidated={query.yearsAtCompanyAddress !== '' && query.yearsAtCompanyAddress < 5}
          register={props.register}
          validationErrors={props.validationErrors}
        />
      </Accordion>
    </>
  );
};

PremisesHistory.propTypes = {
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'company-postcode': PropTypes.shape({}),
    'company-address-line-1': PropTypes.shape({}),
    'company-town': PropTypes.shape({}),
    'company-county': PropTypes.shape({}),
    'company-property-status': PropTypes.shape({}),
    'time-at-company-address': PropTypes.shape({}),
    'company-telephone': PropTypes.shape({}),
  }).isRequired,
};

export default PremisesHistory;
