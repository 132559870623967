import React from 'react';
import PropTypes from 'prop-types';

const StepTrackerControls = ({
  isLast,
  step,
  backDisabled,
  submitDisabled,
  pages,
  backClicked,
  hasErrors,
  emailVerificationError,
}) => {
  const handleEnterPress = (event, fn) => {
    if (event.which === 13) {
      fn();
    }
  };

  const renderSubmitLabel = () => {
    if (isLast && submitDisabled) {
      return 'Submitting...';
    }

    if (isLast) {
      return 'Submit Details';
    }

    return (
      <>
        Next <span>{pages[step + 1].name}</span>
      </>
    );
  };

  return (
    <div className="g-order__actions">
      {!!hasErrors && (
        <div className="row">
          <div className="g-order__error">
            <p className="c-alert c-alert--warning">Sorry, but something went wrong, please try again</p>
          </div>
        </div>
      )}
      <div className="row">
        <div className="g-order__action g-order__action--previous">
          <button
            className="back-button"
            onKeyDown={(evt) => handleEnterPress(evt, backClicked)}
            onClick={(event) => {
              event.preventDefault();
              backClicked();
            }}
            disabled={step === 0 || backDisabled}
            type="button"
          >
            <strong>Back</strong>
            <span>Your info will be saved</span>
          </button>
        </div>

        <div className="g-order__action g-order__action--next">
          <button className="forward-button" disabled={submitDisabled}>
            {renderSubmitLabel()}
          </button>
        </div>
      </div>

      {emailVerificationError && (
        <p className="c-alert c-alert--warning" style={{ margin: '20px 15px 0' }}>
          {emailVerificationError}
        </p>
      )}
    </div>
  );
};

StepTrackerControls.propTypes = {
  isLast: PropTypes.bool,
  step: PropTypes.number.isRequired,
  backClicked: PropTypes.func.isRequired,
  backDisabled: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      heading: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
  hasErrors: PropTypes.bool.isRequired,
  emailVerificationError: PropTypes.string.isRequired,
};

StepTrackerControls.defaultProps = {
  isLast: false,
};

export default StepTrackerControls;
