import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CreditCheck = (props) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCACreditCheck);

  const hasError = !!props.validationErrors && !!props.validationErrors.credit_check;

  const checkboxClasses = classNames({
    'custom-checkbox': true,
    'is-invalid': hasError,
  });

  return (
    <>
      <p>
        Please check your quotation carefully to ensure you are happy with the product, price, and fees. This will also
        be set out clearly within the Order Form you will receive once you have been approved for finance.
      </p>

      <div className={checkboxClasses}>
        <input
          className="custom-control-input"
          type="checkbox"
          id="credit-check"
          name="credit_check"
          onChange={(event) =>
            dispatch({
              type: 'SET_CREDIT_CHECK',
              payload: event.target.checked,
            })
          }
          ref={props.register({ required: true })}
          checked={query.creditCheck}
        />
        <label className="custom-control-label" htmlFor="credit-check">
          By ticking this box you are acknowledging the following:
          <br />
          <br />
          You confirm that you understand the quote and the associated costs and that there isn’t anything that we
          should account for that could affect your understanding.
          <br />
          <br />
          You confirm your consent that as your Credit Broker we will receive commissions as disclosed from the Finance
          Company and 3rd Parties.
          <br />
          <br />
          Prior to application for Credit, we may check your credit eligibility with Experian by performing a soft
          credit search, which will have no impact on your Credit File. Subject to the outcome of any eligibility check,
          we may contact you for further information prior to proceeding with a full Credit Application. <br />
          <br />
          You are consenting to your details being passed to 3rd party credit reference agency to conduct a credit
          search and you have permission from all named parties on the credit application. If you have any questions,
          please speak to your Leasing Consultant before proceeding with the credit application.
          <br />
          <br />
          You have read and understood our{' '}
          <a href="/initial-disclosure-document" target="_blank">
            Initial Disclosure Document
          </a>
          ,{' '}
          <a href="/fair-processing-notice-and-consumer-credit-act-statement" target="_blank">
            Fair Processing Notice
          </a>{' '}
          and{' '}
          <a href="/terms-and-conditions" target="_blank">
            Terms and Conditions
          </a>
          .
        </label>
      </div>
      <div className="invalid-feedback">You need to agree to our terms and conditions.</div>
    </>
  );
};

CreditCheck.propTypes = {
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    credit_check: PropTypes.shape({}),
  }),
};

CreditCheck.defaultProps = {
  validationErrors: null,
};

export default CreditCheck;
