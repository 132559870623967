import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Component Imports
import InputField from '../../../../Forms/InputField';
import TimeSelector from '../../../../Forms/TimeSelector';
import AddressLookup from '../../../../Forms/AddressLookup';

const PreviousPremisesAddress = ({ isValidated, register, validationErrors }) => {
  // React Redux hooks
  const dispatch = useDispatch();
  const query = useSelector((state) => state.OCACompanyInfo);

  return (
    <>
      <AddressLookup
        searchLabel="Postcode"
        id="previous-company-postcode"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_POSTCODE', payload: event.target.value })}
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-company-postcode'] : undefined}
        value={query.previousCompanyPostcode}
        actionName="PREVIOUS_COMPANY"
      />

      <InputField
        labelText="Address line 1"
        id="previous-company-address-line-1"
        placeholder="Address line 1"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_1', payload: event.target.value })}
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-company-address-line-1'] : undefined}
        value={query.previousCompanyAddressLine1}
      />

      <InputField
        labelText="Address line 2"
        id="previous-company-address-line-2"
        placeholder="Address line 2"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_2', payload: event.target.value })}
        value={query.previousCompanyAddressLine2}
      />

      <InputField
        labelText="Address line 3"
        id="previous-company-address-line-3"
        placeholder="Address line 3"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_ADDRESS_LINE_3', payload: event.target.value })}
        value={query.previousCompanyAddressLine3}
      />

      <InputField
        labelText="Town"
        id="previous-company-town"
        placeholder="Town"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_TOWN', payload: event.target.value })}
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-company-town'] : undefined}
        value={query.previousCompanyTown}
      />

      <InputField
        labelText="County"
        id="previous-company-county"
        placeholder="County"
        onChange={(event) => dispatch({ type: 'SET_PREVIOUS_COMPANY_COUNTY', payload: event.target.value })}
        ref={isValidated ? register({ required: true, pattern: /^[A-Za-z\s-]+$/i }) : undefined}
        hasError={isValidated ? !!validationErrors['previous-company-county'] : undefined}
        value={query.previousCompanyCounty}
      />

      <TimeSelector
        labelText="Time at address"
        id="time-at-previous-company-address"
        yearsDispatch="SET_YEARS_AT_PREVIOUS_COMPANY_ADDRESS"
        monthsDispatch="SET_MONTHS_AT_PREVIOUS_COMPANY_ADDRESS"
        ref={isValidated ? register({ required: true }) : undefined}
        hasError={isValidated ? validationErrors : undefined}
        value={[query.yearsAtPreviousCompanyAddress, query.monthsAtPreviousCompanyAddress]}
      />
    </>
  );
};

PreviousPremisesAddress.propTypes = {
  isValidated: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape({
    'previous-company-postcode': PropTypes.shape({}),
    'previous-company-address-line-1': PropTypes.shape({}),
    'previous-company-town': PropTypes.shape({}),
    'previous-company-county': PropTypes.shape({}),
  }).isRequired,
};

export default PreviousPremisesAddress;
