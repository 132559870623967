import React, { forwardRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = forwardRef((_, ref) => {
  // TODO: Add real siteKey
  const [state, setState] = useState({
    siteKey: '6LdSQuwUAAAAAHd1q52y5tO_GqsUlBjR1KdZdxez',
    value: '',
  });

  const handleChange = (value) => {
    setState({ ...state, value: value !== null ? value : '' });
  };

  return (
    <>
      <input type="hidden" name="recaptcha-token" value={state.value} data-recaptcha-value={state.value} ref={ref} />

      <ReCAPTCHA style={{ display: 'inline-block' }} sitekey={state.siteKey} onChange={handleChange} />
    </>
  );
});

export default Recaptcha;
